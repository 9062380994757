var _dec, _dec2, _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import Component from "@ember/component";
import I18n from "I18n";
import discourseComputed from "discourse-common/utils/decorators";
export default Component.extend((_dec = discourseComputed("topic.pinned_globally", "pinned"), _dec2 = discourseComputed("pinned", "topic.deleted", "topic.unpinned"), (_obj = {
  pluginApiIdentifiers: ["pinned-button"],
  descriptionKey: "help",
  classNames: "pinned-button",
  classNameBindings: ["isHidden"],
  reasonText(pinnedGlobally, pinned) {
    const globally = pinnedGlobally ? "_globally" : "";
    const pinnedKey = pinned ? `pinned${globally}` : "unpinned";
    const key = `topic_statuses.${pinnedKey}.help`;
    return I18n.t(key);
  },
  isHidden(pinned, deleted, unpinned) {
    return deleted || !pinned && !unpinned;
  }
}, (_applyDecoratedDescriptor(_obj, "reasonText", [_dec], Object.getOwnPropertyDescriptor(_obj, "reasonText"), _obj), _applyDecoratedDescriptor(_obj, "isHidden", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isHidden"), _obj)), _obj)));