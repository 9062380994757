var _dec, _dec2, _dec3, _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import ComboBoxSelectBoxHeaderComponent from "select-kit/components/combo-box/combo-box-header";
import discourseComputed from "discourse-common/utils/decorators";
import { readOnly } from "@ember/object/computed";
import { schedule } from "@ember/runloop";
import { htmlSafe } from "@ember/template";
export default ComboBoxSelectBoxHeaderComponent.extend((_dec = discourseComputed("selectedContent.color"), _dec2 = discourseComputed("selectedContent.text_color"), _dec3 = discourseComputed("selectedContent", "categoryBackgroundColor", "categoryTextColor"), (_obj = {
  classNames: ["category-drop-header"],
  classNameBindings: ["categoryStyleClass"],
  categoryStyleClass: readOnly("site.category_style"),
  categoryBackgroundColor(categoryColor) {
    return categoryColor || "#e9e9e9";
  },
  categoryTextColor(categoryTextColor) {
    return categoryTextColor || "#333";
  },
  categoryStyle(category, categoryBackgroundColor, categoryTextColor) {
    const categoryStyle = this.siteSettings.category_style;
    if (categoryStyle === "bullet") {
      return;
    }
    if (category) {
      if (categoryBackgroundColor || categoryTextColor) {
        let style = "";
        if (categoryBackgroundColor) {
          if (categoryStyle === "box") {
            style += `border-color: #${categoryBackgroundColor}; background-color: #${categoryBackgroundColor};`;
            if (categoryTextColor) {
              style += `color: #${categoryTextColor};`;
            }
          }
        }
        return htmlSafe(style);
      }
    }
  },
  didInsertElement() {
    this._super(...arguments);
    schedule("afterRender", () => {
      if (this.categoryStyle) {
        this.element.setAttribute("style", this.categoryStyle);
        this.element.querySelector(".caret-icon").setAttribute("style", this.categoryStyle);
      }
    });
  }
}, (_applyDecoratedDescriptor(_obj, "categoryBackgroundColor", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoryBackgroundColor"), _obj), _applyDecoratedDescriptor(_obj, "categoryTextColor", [_dec2], Object.getOwnPropertyDescriptor(_obj, "categoryTextColor"), _obj), _applyDecoratedDescriptor(_obj, "categoryStyle", [_dec3], Object.getOwnPropertyDescriptor(_obj, "categoryStyle"), _obj)), _obj)));