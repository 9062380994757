var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { inject as service } from "@ember/service";
import { action } from "@ember/object";
import { setOwner } from "@ember/application";
import { TOOLTIP } from "float-kit/lib/constants";
import { guidFor } from "@ember/object/internals";
import FloatKitInstance from "float-kit/lib/float-kit-instance";
let DTooltipInstance = (_class = class DTooltipInstance extends FloatKitInstance {
  constructor(owner, trigger, options = {}) {
    super(...arguments);
    _initializerDefineProperty(this, "tooltip", _descriptor, this);
    setOwner(this, owner);
    this.options = {
      ...TOOLTIP.options,
      ...options
    };
    this.id = trigger.id || guidFor(trigger);
    this.trigger = trigger;
    this.setupListeners();
  }
  onMouseMove(event) {
    if (this.trigger.contains(event.target) && this.expanded) {
      return;
    }
    this.onTrigger(event);
  }
  onClick(event) {
    if (this.expanded && this.untriggers.includes("click")) {
      this.onUntrigger(event);
      return;
    }
    this.onTrigger(event);
  }
  onMouseLeave(event) {
    if (this.untriggers.includes("hover")) {
      this.onUntrigger(event);
    }
  }
  async onTrigger() {
    this.options.beforeTrigger?.(this);
    await this.show();
  }
  async onUntrigger() {
    await this.close();
  }
  async destroy() {
    await this.close();
    this.tearDownListeners();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tooltip", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "onMouseMove", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseLeave", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTrigger", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onTrigger"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUntrigger", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onUntrigger"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroy", [action], Object.getOwnPropertyDescriptor(_class.prototype, "destroy"), _class.prototype)), _class);
export { DTooltipInstance as default };