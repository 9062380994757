var _class;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import Modifier from "ember-modifier";
import { registerDestructor } from "@ember/destroyable";
import { bind } from "discourse-common/utils/decorators";
let FloatKitCloseOnClickOutside = (_class = class FloatKitCloseOnClickOutside extends Modifier {
  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, instance => instance.cleanup());
  }
  modify(element, [trigger, closeFn]) {
    this.closeFn = closeFn;
    this.trigger = trigger;
    this.element = element;
    document.addEventListener("click", this.check, {
      passive: true
    });
  }
  check(event) {
    if (this.element.contains(event.target)) {
      return;
    }
    if (this.trigger instanceof HTMLElement && this.trigger.contains(event.target)) {
      return;
    }
    this.closeFn();
  }
  cleanup() {
    document.removeEventListener("click", this.check);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "check", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "check"), _class.prototype)), _class);
export { FloatKitCloseOnClickOutside as default };