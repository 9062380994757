var _dec, _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import SelectKitFilterComponent from "select-kit/components/select-kit/select-kit-filter";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import { action } from "@ember/object";
export default SelectKitFilterComponent.extend((_dec = discourseComputed("placeholder", "selectKit.hasSelection"), (_obj = {
  classNames: ["multi-select-filter"],
  computedPlaceholder(placeholder, hasSelection) {
    if (this.hidePlaceholderWithSelection && hasSelection) {
      return "";
    }
    return isEmpty(placeholder) ? "" : placeholder;
  },
  onPaste(event) {
    const data = event?.clipboardData;
    if (!data) {
      return;
    }
    const parts = data.getData("text").split("|").filter(Boolean);
    if (parts.length > 1) {
      event.stopPropagation();
      event.preventDefault();
      this.selectKit.append(parts);
      return false;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "computedPlaceholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "computedPlaceholder"), _obj), _applyDecoratedDescriptor(_obj, "onPaste", [action], Object.getOwnPropertyDescriptor(_obj, "onPaste"), _obj)), _obj)));