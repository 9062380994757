var _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import DiscourseURL from "discourse/lib/url";
import MiniTagChooser from "select-kit/components/mini-tag-chooser";
import { makeArray } from "discourse-common/lib/helpers";
import { action } from "@ember/object";
export default MiniTagChooser.extend((_obj = {
  pluginApiIdentifiers: ["tags-intersection-chooser"],
  attributeBindings: ["selectKit.options.categoryId:category-id"],
  classNames: ["tags-intersection-chooser"],
  mainTag: null,
  additionalTags: null,
  didReceiveAttrs() {
    this._super(...arguments);
    this.set("value", makeArray(this.mainTag).concat(makeArray(this.additionalTags)));
  },
  onChange(tags) {
    if (tags.includes(this.mainTag)) {
      const remainingTags = tags.filter(t => t !== this.mainTag);
      if (remainingTags.length >= 1) {
        DiscourseURL.routeTo(`/tags/intersection/${this.mainTag}/${remainingTags.join("/")}`);
      } else {
        DiscourseURL.routeTo("/tags");
      }
    } else {
      if (tags.length >= 2) {
        DiscourseURL.routeTo(`/tags/intersection/${tags.join("/")}`);
      } else {
        DiscourseURL.routeTo("/tags");
      }
    }
  }
}, (_applyDecoratedDescriptor(_obj, "onChange", [action], Object.getOwnPropertyDescriptor(_obj, "onChange"), _obj)), _obj));