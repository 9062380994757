var _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import Component from "@ember/component";
import { bind } from "discourse-common/utils/decorators";
import { next } from "@ember/runloop";
import { computed } from "@ember/object";
export default Component.extend((_obj = {
  classNames: ["select-kit-body"],
  classNameBindings: ["emptyBody:empty-body"],
  emptyBody: computed("selectKit.{filter,hasNoContent}", function () {
    return false;
  }),
  didInsertElement() {
    this._super(...arguments);
    this.element.style.position = "relative";
    document.addEventListener("click", this.handleClick, true);
    this.selectKit.mainElement().addEventListener("keydown", this._handleKeydown, true);
  },
  willDestroyElement() {
    this._super(...arguments);
    document.removeEventListener("click", this.handleClick, true);
    this.selectKit.mainElement()?.removeEventListener("keydown", this._handleKeydown, true);
  },
  handleClick(event) {
    if (!this.selectKit.isExpanded || !this.selectKit.mainElement()) {
      return;
    }
    if (this.selectKit.mainElement().contains(event.target)) {
      return;
    }
    this.selectKit.close(event);
  },
  _handleKeydown(event) {
    if (!this.selectKit.isExpanded || event.key !== "Tab") {
      return;
    }
    next(() => {
      if (this.isDestroying || this.isDestroyed || this.selectKit.mainElement()?.contains(document.activeElement)) {
        return;
      }
      this.selectKit.close(event);
    });
  }
}, (_applyDecoratedDescriptor(_obj, "handleClick", [bind], Object.getOwnPropertyDescriptor(_obj, "handleClick"), _obj), _applyDecoratedDescriptor(_obj, "_handleKeydown", [bind], Object.getOwnPropertyDescriptor(_obj, "_handleKeydown"), _obj)), _obj));