var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Service from "@ember/service";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import DTooltipInstance from "float-kit/lib/d-tooltip-instance";
import { guidFor } from "@ember/object/internals";
import { tracked } from "@glimmer/tracking";
import { updatePosition } from "float-kit/lib/update-position";
let Tooltip = (_class = class Tooltip extends Service {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "activeTooltip", _descriptor, this);
    _initializerDefineProperty(this, "portalOutletElement", _descriptor2, this);
  }
  /**
   * Render a tooltip
   *
   * @param {Element | DTooltipInstance}
   *  - trigger - the element that triggered the tooltip, can also be an object implementing `getBoundingClientRect`
   *  - tooltip - an instance of a tooltip
   * @param {Object} [options] - options, if trigger given as first argument
   * @param {String | Element | Component} [options.content] - Specifies the content of the tooltip
   * @param {Integer} [options.maxWidth] - Specifies the maximum width of the content
   * @param {Object} [options.data] - An object which will be passed as the `@data` argument when content is a `Component`
   * @param {Boolean} [options.arrow] - Determines if the tooltip has an arrow
   * @param {Boolean} [options.offset] - Displaces the content from its reference trigger in pixels
   * @param {String} [options.identifier] - Add a data-identifier attribute to the trigger and the content
   * @param {Boolean} [options.inline] - Improves positioning for trigger that spans over multiple lines
   *
   * @returns {Promise<DTooltipInstance>}
   */
  async show() {
    let instance;
    if (arguments[0] instanceof DTooltipInstance) {
      instance = arguments[0];
      if (this.activeTooltip === instance && this.activeTooltip.expanded) {
        return;
      }
    } else {
      const trigger = arguments[0];
      if (this.activeTooltip && this.activeTooltip.id === (trigger?.id?.length ? trigger.id : guidFor(trigger)) && this.activeTooltip.expanded) {
        this.activeTooltip?.close();
        return;
      }
      instance = new DTooltipInstance(getOwner(this), trigger, arguments[1]);
    }
    await this.replace(instance);
    instance.expanded = true;
    return instance;
  }

  /**
   * Replaces any active tooltip
   */
  async replace(tooltip) {
    await this.activeTooltip?.close();
    this.activeTooltip = tooltip;
  }

  /**
   * Closes the active tooltip
   * @param {DTooltipInstance} [tooltip] - the tooltip to close, if not provider will close any active tooltip
   */
  async close(tooltip) {
    if (this.activeTooltip && tooltip && this.activeTooltip.id !== tooltip.id) {
      return;
    }
    await this.activeTooltip?.close();
    this.activeTooltip = null;
  }

  /**
   * Update the tooltip position
   * @param {DTooltipInstance} [tooltip] - the tooltip to update, if not provider will update any active tooltip
   */
  async update(tooltip) {
    const instance = tooltip || this.activeTooltip;
    if (!instance) {
      return;
    }
    await updatePosition(instance.trigger, instance.content, instance.options);
    await instance.show();
  }

  /**
   * Register event listeners on a trigger to show a tooltip
   *
   * @param {Element} trigger - the element that triggered the tooltip, can also be an object implementing `getBoundingClientRect`
   * @param {Object} [options] - @see `show`
   *
   * @returns {DTooltipInstance} An instance of the tooltip
   */
  register(trigger, options = {}) {
    return new DTooltipInstance(getOwner(this), trigger, {
      ...options,
      listeners: true,
      beforeTrigger: async tooltip => {
        await this.replace(tooltip);
      }
    });
  }
  registerPortalOutletElement(element) {
    this.portalOutletElement = element;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeTooltip", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "portalOutletElement", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "show", [action], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replace", [action], Object.getOwnPropertyDescriptor(_class.prototype, "replace"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "register", [action], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerPortalOutletElement", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerPortalOutletElement"), _class.prototype)), _class);
export { Tooltip as default };